<template>
  <div>
    <ul
      ref="fallbox"
      class="home-product grace-space-between"
      :style="{ padding: '10px' }"
    >
      <li
        v-for="(it, zIndex) in col"
        :ref="'col' + zIndex"
        :key="it"
        class="grace-waterfall-item"
        :style="{ width: 100 / col - 1.7 + '%' }"
      >
        <div v-for="(nL, n) in newList[zIndex]" :key="n" class="items">
          <!-- 插槽，用户如何对数据渲染 -->
          <slot :data="nL" />

        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'Waterfalls',
  props: {
    col: {
      type: Number,
      default: 2
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    index: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      saveData: [],
      listTotal: []
    }
  },
  computed: {
    concatList() {
      return this.listTotal
    },
    newList() {
      const myData = this.listTotal // 数据列表
      const col = this.col
      if (myData.length <= 0) return []
      const result = myData.reduce((total, item, index) => {
        if (!Array.isArray(total)) {
          const arr = []
          arr.length = col
          total = arr.map(i => ([]))
        }
        const remainder = index % col
        if (remainder === 0) {
          !total[col - 1] ? total[col - 1] = [item] : total[col - 1].push(item)
        } else {
          !total[remainder - 1] ? total[remainder - 1] = [item] : total[remainder - 1].push(item)
        }
        return total
      }, 0)
      return result
    }
  },
  watch: {
    index(n, o) {
      if (n !== o) {
        this.saveData = []
      }
    },
    list: {
      deep: true,
      immediate: true,
      handler(n) {
        if (n) {
          this.listTotal = this.listTotal.concat(this.list)
        }
      }
    }
  },
  mounted() {
  },

  methods: {}
}
</script>
<style lang="less" scoped>
.home-product {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .grace-waterfall-item {
    // background: #000;

    .items {
      margin-bottom: 5px;
      overflow: hidden;
    }
  }
}
</style>
