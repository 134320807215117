<template>
  <!-- 悦淘优选商品列表 -->
  <div class="yt-optimization-malls">
    <!-- 商品列表 -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <filter-tabs :options="options" />
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="recommend_flow_list">
          <skeleton v-if="!finished && goodsList.length <= 0 " type="list" />
          <Waterfalls
            v-else
            id="dataList"
            :col="2"
            :list="goodsList"
          >
            <template slot-scope="{ data }">
              <div class="recommended_item" @click="handleClick(data)">
                <div v-if="data.markerUrl" class="markerUrlIcon">
                  <img :src="data.markerUrl">
                  <!-- {{data.markerUrl}} -->
                </div>

                <div class="recommended_item_img">
                  <imgLoading :src="data.goodCover" />
                </div>

                <div class="recommended_item_box">
                  <div class="tit">
                    {{ data.goodName }}
                  </div>
                  <div class="desc">
                    <span v-if="data.couponAmountName && data.couponAmountName !== ''" class="desc_jd">
                      {{ data.couponAmountName !== '' ? data.couponAmountName + '元券' : '' }}
                    </span>
                    <span v-if="data.growthValue" class="desc_one">
                      分享赚¥{{ data.growthValue.share }}
                    </span>
                  </div>
                  <div class="money">
                    <em>¥</em>{{ data.goodVipPrice }}
                    <span>
                      <em>¥</em>
                      <span>{{ data.goodPrice }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </Waterfalls>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import ImgLoading from '@/component/imgLoading'
import Waterfalls from '@/component/Waterfalls'
import skeleton from '@/component/skeleton/index'

import { List, PullRefresh, Tab, Tabs } from 'vant'
import Vue from 'vue'
import { isWeChatApplet, isYTApp } from '@/utils/common/validate'
import { wxConfigInit } from '@/utils/wx-api'
import { changeURLArg, delUrlParam } from '@/utils/common/utils'
import FilterTabs from '@/component/filterTabs'
import {
  fetchOptimizationKingKongMallList,
  fetchOptimizationShareInfo
} from '@/services/optimization'

const isYtApp = isYTApp()
Vue.use(Tabs).use(Tab).use(List).use(PullRefresh)
export default {
  name: 'YtOptimizationMalls',
  components: {
    FilterTabs,
    Waterfalls,
    skeleton,
    ImgLoading
  },
  data() {
    return {
      finished: false,
      loading: false,
      refreshing: false,
      error: false,
      bannerUrl: '0',
      location: '',
      goodsList: [],
      listQuery: {
        page: 1,
        pageSize: 10
      },
      shareInfo: {},

      options: {
        active: 0,
        activeChange: (index) => {
          console.log(index)
        },
        tabsList: [
          {
            title: '综合',
            sort: {
              showSort: false,
              sortTag: '',
              sortChange: (type) => {
                console.log(type)
              }
            }
          },
          {
            title: '销量',
            sort: {
              showSort: true,
              sortTag: '',
              sortChange: (type) => {
                console.log(type)
              }
            }
          },
          {
            title: '价格',
            sort: {
              showSort: true,
              sortTag: '',
              sortChange: (type) => {
                console.log(type)
              }
            }
          }
        ]
      },

      type: 1, // 进入的商品列表类型
      id: null // 商品类型id
    }
  },
  computed: {
  },
  created() {
    if (isYtApp) {
      window.RNshareDataChange = this.handleShowShare
    }
    const { type = 111, id, location } = this.$route.query
    this.type = Number(type)
    this.location = location
    this.id = id
    this.getShareInfo()
  },
  mounted() {
    this.getOptimizationGoodsList()
  },
  methods: {
    /**
     * 触发打开App分享设置
     * 2021-05-28 16:31:42
     * @author SnowRock
     */
    handleAppShare(type) {
      this.$store.dispatch('RNrouter', {
        eventName: 'isShowShare',
        isShow: type
      })
    },
    onLoad() {
      this.listQuery.page++
      this.getOptimizationGoodsList()
    },
    /**
     * 下拉刷新
     */
    onRefresh() {
      // 清空列表数据
      this.finished = false
      this.goodsList = []
      this.listQuery.page = 0

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    /**
     * 获取参数配置
     * 2021-07-14 02:20:00
     * @author SnowRock
     */
    async getShareInfo() {
      const res = await fetchOptimizationShareInfo({ id: this.id })
      const { share_img, share_title, share_subtitle, jump_url, name } = res.data
      console.log(jump_url)
      this.shareInfo = {
        imgUrl: share_img,
        title: share_title,
        desc: share_subtitle,
        link: window.location.href
      }
      document.title = name || share_title || ''
      if (!isYtApp) {
        this.handleShowShare()
      }
    },
    /**
     * 分享参数设置
     * 2021-06-03 17:40:39
     * @author SnowRock
     */
    async handleShowShare() {
      const url = window.location.href
      const shareInfo = {
        link: delUrlParam(delUrlParam(url, 'mid'), 'token'),
        ...this.shareInfo
      }
      if (isYtApp) {
        const recode = localStorage.getItem('recode') || ''
        const appShare = {
          eventName: 'share',
          image: shareInfo.imgUrl, // 分享标题
          title: shareInfo.title,
          des: shareInfo.desc,
          url: changeURLArg(shareInfo.link, 'codeNumber', recode)
        }
        this.$store.dispatch('RNrouter', appShare)
      } else {
        await wxConfigInit(shareInfo)
      }
    },
    /**
     * 获取商品列表
     * 2021-07-13 18:05:00
     * @author SnowRock
     */
    async getOptimizationGoodsList() {
      try {
        const { location } = this
        // 判断是从banner 或者 金刚位进入的
        let type = 1
        if (location === 'banner') {
          type = 3
        }
        if (location === 'king') {
          type = 1
        }
        const res = await fetchOptimizationKingKongMallList({
          ...this.listQuery,
          type: type,
          id: this.id
        })
        const { code, data } = res
        this.loading = false
        if (code === 200) {
          const { goods_info: list } = data
          if (this.refreshing) {
            this.goodsList = []
            this.refreshing = false
          }
          this.goodsList = list
          this.finished = list && list.length <= 0
        } else {
          this.error = true
          this.listQuery.page--
        }
      } catch (e) {
        this.loading = false
        this.error = true
        this.listQuery.page--
      }
    },
    /**
     * 处理点击事件
     * 2021-06-03 16:20:53
     * @author SnowRock
     */
    handleClick({ goodId, skuId, productType = 1 }) {
      this.$store.commit('ScrollDateChange', {
        Scroll: 200
      })

      const obj = {
        product_type: 1,
        goodId: goodId,
        skuId: skuId
      }
      if (!isWeChatApplet()) {
        this.$store.dispatch('godetail', obj)
      } else {
        // eslint-disable-next-line no-undef
        wx.miniProgram.navigateTo({
          url: '/page/mall/pages/selfDetail/selfDetail?productId=' + goodId + '&productSkuId=' + skuId + '&productType=' + 1
        })
      }
    }
  }
}
</script>

<style lang="less">
</style>
<style lang="less" scoped>
.yt-optimization-malls {
  min-height: 100vh;
  text-align: left;
  background-color: #f1f1f1;
  .yt-optimization-malls-banner {
    width: 100%;
    height: 421px;
    position: relative;
    div {
      position: absolute;
      z-index: 0;
      top: -1px;
      left: 0;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .yt-optimization-malls-transition {
    width: 290px;
    height: auto;
    z-index: 23;
    position: relative;
    margin: 30px auto 10px;

    img {
      width: 290px;
      height: auto;
    }
  }
  .recommend_flow_list {
    width: 100%;
    .recommended_item {
      position: relative;
      .recommended_item_img {
        img {
          height: 100px;
        }
        // height: 170px;
      }
      .recommended_item_box {
        margin: 0 8px;
      }
      width: 100%;
      background: #fff;
      border-radius: 9px;
      margin-bottom: 9px;

      overflow: hidden;

      .tit {
        color: #191919;
        font-size: 14px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2; /*要显示的行数*/
        -webkit-box-orient: vertical;
        margin: 10px 0 5px 0;
        line-height: 20px;
        font-weight: bold;
        img {
          width: 16px;
          height: 14px;
          border-radius: 4px;
        }
      }
      .desc {
        .desc_one {
          color: red;
          font-size: 10px;
          background: rgba(212, 146, 146, 0.4);
          display: initial;
          border: 1px solid red;
          border-radius: 4px;
          padding: 0 4px;
          box-sizing: border-box;
        }
        .desc_jd {
          width: 68px;
          height: 14px;
          background: url("~@/assets/images/yhj_red.png") no-repeat;
          background-size: 100% 100%;
          font-size: 10px;
          color: #fff;
          padding: 1px 3px;
          box-sizing: border-box;
          margin-right: 12px;
        }
      }
      .money {
        font-size: 17px;
        color: #c82519;
        font-weight: 500;
        margin-top: 8px;
        margin-bottom: 4px;
        span {
          text-decoration: line-through;
          color: #bbbbbb;
          font-size: 12px;
          margin-left: 2px;
          line-height: 23px;
        }
        em {
          font-size: 12px;
          font-style: normal;
        }
      }
    }
  }
  .markerUrlIcon {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 98;

    img {
      width: auto;
      height: 70px;
    }
  }
}
</style>
