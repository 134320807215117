<template>
  <div>
    <ul class="filterTabsUl">
      <li
        v-for="(item, index) in options.tabsList"
        :key="index"
        :class="[newActive === index ? 'tabsActive' : '']"
        @click="activeChange(index)"
      >
        {{ item.title }}
        <span v-if="item.sort && item.sort.showSort" class="caret-wrapper">
          <i
            class="sort-caret ascending"
            :style="{
              'border-bottom-color': item.sort.sortTag
                ? item.sort.sortTag === 'add'
                  ? '#E33F44'
                  : '#CCCCCC'
                : '#CCCCCC',
            }"
            @click.stop="setSortData(index, 'add', item.sort.sortChange)"
          >
            <!-- <img class="sortIcon" src="../../assets/images/gray_up_arrow.png" />  -->
          </i>
          <i
            class="sort-caret descending"
            :style="{
              'border-top-color': item.sort.sortTag
                ? item.sort.sortTag === 'sub'
                  ? '#E33F44'
                  : '#CCCCCC'
                : '#CCCCCC',
            }"
            @click.stop="setSortData(index, 'sub', item.sort.sortChange)"
          >
            <!-- <img class="sortIcon" src="../../assets/images/gray_down_arrow.png" /> -->
          </i>
        </span>

        <span
          v-if="item.filter && item.filter.showFilter"
          class="caret-wrapper1"
          @click.stop="filterChange(index, 'filter', item.filter.filterChange)"
        >
          <img class="filterIcon" src="../../assets/images/filterIcon.png">
          <!-- <van-icon name="filter-o" style="font-size: 14px" /> -->
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
import Vue from 'vue'
import { Icon } from 'vant'

Vue.use(Icon)
export default {
  name: 'FilterTabs',
  props: {
    options: {
      type: Object,
      default: () => {
        return {}
      },
      require: true
    }
  },
  data() {
    return {
      newActive: 0,
      sortTag: ''
    }
  },
  methods: {
    activeChange(index) {
      if (
        !this.options.tabsList[index].filter ||
        !this.options.tabsList[index].filter.showFilter
      ) {
        this.newActive = index
        this.options.activeChange(index)
      } else {
        this.options.tabsList[index].filter.filterChange()

        //    filterChange(index, 'filter', item.filter.filterChange)
      }
    },
    setSortData(index, type, callBack) {
      if (index !== this.newActive) {
        this.activeChange(index)
      }
      if (this.options.tabsList[index].sort.sortTag !== type) {
        this.options.tabsList[index].sort.sortTag = type
        // this.sortTag = type;
        callBack(type)
      }
    },
    filterChange(index, type, callBack) {
      callBack(type)
    }
  }
}
</script>
<style lang="less" scoped>
.filterTabsUl {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 1px;
  background: #ffffff;

  li {
    background: #f7f7f7;
    border-radius: 20px;
    position: relative;
    width: 70px;
    height: 24px;
    font-size: 12px;
    color: #000;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .caret-wrapper {
      // margin: 0 5px;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      vertical-align: middle;
      cursor: pointer;
      overflow: initial;
      position: relative;
      height: 24px;
      width: 24px;

      .sort-caret {
        width: 0;
        height: 0;
        border: 5px solid transparent;
        position: absolute;
        left: 5px;
      }

      .ascending {
        border-bottom-color: #c0c4cc;
        top: 1px;
      }

      .descending {
        border-top-color: #c0c4cc;
        bottom: 1px;
      }

      /deep/ .van-icon {
        font-size: 10px;
      }
    }

    .caret-wrapper1 {
      margin: 0 5px;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      vertical-align: middle;
      cursor: pointer;
      overflow: initial;
      // position: relative;
      // height: 24px;
      // width: 24px;
    }
  }

  .tabsActive {
    color: #e33f44;
    transition: all 1s;
    background: #fceeee;

    // &::after {
    //   position: absolute;
    //   content: "";
    //   height: 2px;
    //   background: #ff001c;
    //   //width: 100%;
    //   width: 50%;
    //   left: 50%;
    //   bottom: -10px;
    //   transform: translate(-50%, -50%);
    //   transition: all 1s ease;
    //   /*animation:0.1s linear silde;*/
    // }
  }
}

.filterIcon {
  width: 11px;
  height: 11px;
}

.sortIcon {
  width: 10px;
  height: 7px;
}
</style>
