import request from '@/services/request.js'

/**
 * 获取金刚位列表
 * 2021-07-13 10:54:54
 * @author SnowRock
 * @returns {Promise<*|undefined>}
 */
export function fetchKingKongList() {
  return request({
    url: '/youxuan/kingkong/getKingkongList',
    method: 'post'
  })
}

/**
 * 获取banner信息
 * 2021-07-13 10:54:54
 * @author SnowRock
 * @returns {Promise<*|undefined>}
 */
export function fetchOptimizationBannerList() {
  return request({
    url: '/youxuan/banner/getBannerList',
    method: 'post'
  })
}

/**
 * 获取banner商品列表
 * 2021-07-13 10:54:54
 * @author SnowRock
 * @returns {Promise<*|undefined>}
 */
export function fetchOptimizationBannerMallList(params) {
  return request({
    url: '/youxuan/banner/getBannerProductList',
    data: params,
    method: 'post'
  })
}

/**
 * 获取金刚位商品列表
 * 2021-07-13 10:54:54
 * @author SnowRock
 * @returns {Promise<*|undefined>}
 */
export function fetchOptimizationKingKongMallList(params) {
  return request({
    url: '/youxuan/kingkong/getKingkongProductList',
    data: params,
    method: 'post'
  })
}

/**
 * 获取首页商品类目 - 悦淘优选
 * 2021-07-13 10:54:54
 * @author SnowRock
 * @returns {Promise<*|undefined>}
 */
export function fetchOptimizationCategoryList(params) {
  return request({
    url: '/youxuan/category/getCategoryList',
    data: params,
    method: 'post'
  })
}

/**
 * 获取首页类目下商品列表 - 悦淘优选
 * 2021-07-13 10:54:54
 * @author SnowRock
 * @returns {Promise<*|undefined>}
 */
export function fetchOptimizationCategoryMallList(params) {
  return request({
    url: '/youxuan/category/getCategoryProductList',
    data: params,
    method: 'post'
  })
}

/**
 * 获取搜索商品 - 悦淘优选
 * 2021-07-13 10:54:54
 * @author SnowRock
 * @returns {Promise<*|undefined>}
 */
export function fetchOptimizationSearchMallList(params) {
  return request({
    url: '/youxuan/goods/goodsQuery',
    data: params,
    method: 'post'
  })
}
/**
 * 获取秒杀列表 - 悦淘优选
 * 2021-07-13 23:25:00
 * @author SnowRock
 * @returns {Promise<*|undefined>}
 */
export function fetchOptimizationKillHomeMallList(params) {
  return request({
    url: '/youxuan/getSeckill',
    data: params,
    method: 'post'
  })
}
/**
 * 获取秒杀列表 - 悦淘优选
 * 2021-07-13 23:25:00
 * @author SnowRock
 * @returns {Promise<*|undefined>}
 */
export function fetchOptimizationKillMallList(params) {
  return request({
    url: '/youxuan/getSeckillByActivityId',
    data: params,
    method: 'post'
  })
}
/**
 * 获取秒杀时间列表 - 悦淘优选
 * 2021-07-13 23:25:00
 * @author SnowRock
 * @returns {Promise<*|undefined>}
 */
export function fetchOptimizationKillTimeList(params) {
  return request({
    url: '/youxuan/getSeckillTime',
    data: params,
    method: 'post'
  })
}

/**
 * 获取商品列表分享参数
 * 2021-07-13 23:25:00
 * @author SnowRock
 * @returns {Promise<*|undefined>}
 */
export function fetchOptimizationShareInfo(params) {
  return request({
    url: '/youxuan/kingkong/shareInfo',
    data: params,
    method: 'post'
  })
}
